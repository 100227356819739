import Tippy, { type TippyProps } from '@tippyjs/react'
import type { ElementType, ReactElement, ReactNode } from 'react'

const Tooltip = ({
    tooltip,
    children,
    as = 'div',
    className,
    options,
}: {
    tooltip?: ReactNode | string
    children?: ReactElement
    as?: ElementType
    className?: string
    options?: TippyProps
}) => {
    if (!tooltip) {
        return children
    }

    const As = as

    return (
        <Tippy content={tooltip} theme="light" {...options}>
            <As className={className}>{children}</As>
        </Tippy>
    )
}

export default Tooltip
