import toast from 'core/utils/toast'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { Input, OptionWithTooltip, selectStyles } from '../shared/select-styles'
import { get, del, put, post } from 'core/services/http-service'
import type { Account, ListLight, MailCollectType, Provider, SiteLight } from 'components/oauth/types'
import { createId } from '@paralleldrive/cuid2'
import { DateTime } from 'luxon'
import { useLocation } from 'react-router'
import { setCookie } from 'components/oauth/cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons'
import SmallLoader from 'components/shared/small-loader'
import { ReactComponent as Google } from '../../assets/sso/google.svg'
import { ReactComponent as Gmail } from '../../assets/gmail.svg'
import { ReactComponent as Outlook } from '../../assets/outlook.svg'
import { ReactComponent as Microsoft } from '../../assets/sso/microsoft.svg'
import { ReactComponent as SharePoint } from '../../assets/sharepoint.svg'
import Modal from 'components/shared/modal'
import SharePointAdd from './microsoft/share-point-add'
import SharePointList from './microsoft/share-point-list'

function OauthAccounts() {
    const { t } = useTranslation()
    const location = useLocation()

    const accountCollectTypes = [
        {
            value: 'ALL',
            label: t('web_me_options_linked_mailbox_collect_type_ALL'),
            help: t('web_me_options_linked_mailbox_collect_type_ALL_help'),
        },
        {
            value: 'MAIL_CONTENT',
            label: t('web_me_options_linked_mailbox_collect_type_MAIL_CONTENT'),
            help: t('web_me_options_linked_mailbox_collect_type_MAIL_CONTENT_help'),
        },
        {
            value: 'MAIL_ATTACHMENTS',
            label: t('web_me_options_linked_mailbox_collect_type_MAIL_ATTACHMENTS'),
            help: t('web_me_options_linked_mailbox_collect_type_MAIL_ATTACHMENTS_help'),
        },
        {
            value: 'NONE',
            label: t('web_me_options_linked_mailbox_collect_type_NONE'),
            help: t('web_me_options_linked_mailbox_collect_type_NONE_help'),
        },
    ] as const

    const [loading, setLoading] = useState<boolean>(true)
    const [providers, setProviders] = useState<Provider[]>([])
    const [accounts, setAccounts] = useState<Account[]>([])
    const [collectByAccount, setCollectByAccount] = useState<{ accountId: string; collectType: MailCollectType }[]>([])
    const [sharePointModal, showSharePointModal] = useState<boolean>(false)
    const [sharePointAccount, setSharePointAccount] = useState<Account>()
    const [sharePointSite, setSharePointSite] = useState<SiteLight>()
    const [sharePointLists, setSharePointLists] = useState<(ListLight & { selected: boolean })[]>([])
    const [savingSharePoint, setSavingSharePoint] = useState<boolean>(false)

    useEffect(() => {
        for (const account of accounts) {
            setCollectByAccount(prev => [...prev, { accountId: account.uuid, collectType: account.mailCollectType }])
        }
    }, [accounts])

    async function loadProviders() {
        setLoading(true)

        const {
            data: { providers },
        } = await get<void, { data: { providers: Provider[] } }>('/v1/web/oauth/providers')
        setProviders(providers)

        setLoading(false)
    }

    async function loadAccounts() {
        setLoading(true)

        const {
            data: { accounts },
        } = await get<void, { data: { accounts: Account[] } }>('/v1/web/oauth/accounts')
        setAccounts(accounts)

        setLoading(false)
    }

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        loadProviders()
        loadAccounts()
    }, [])

    async function removeAccount(accountId: string) {
        const remove = confirm(t('web_me_options_unlink_account_confirm'))
        if (remove) {
            const {
                data: { success, email },
            } = await del<{ data: { success: boolean; email: string } }>(`/v1/web/oauth/accounts/${accountId}`)

            if (success) {
                toast('success', t('web_me_options_mail_unlinked', { email }))
            } else {
                toast('error', t('web_me_options_linked_mailbox_error'))
            }

            await loadAccounts()
        }
    }

    async function accountCollectType(accountId: string, collectType: MailCollectType) {
        const putData = {
            collectType,
        }

        const {
            data: { success },
        } = await put<typeof putData, { data: { success: boolean } }>(
            `/v1/web/oauth/accounts/${accountId}/collect-type`,
            putData
        )

        if (success) {
            toast('success', t('web_me_options_linked_mailbox_collect_type_updated'))
            setCollectByAccount(prev =>
                prev.map(c => {
                    if (c.accountId === accountId) {
                        return { ...c, collectType }
                    }

                    return c
                })
            )
        } else {
            toast('error', t('web_me_options_linked_mailbox_error'))
        }
    }

    async function saveSharePointSite() {
        setSavingSharePoint(true)

        const postData = {
            site: sharePointSite.id,
            lists: sharePointLists.map(({ id, selected }) => ({ id, selected })),
        }

        const { data } = await post<
            typeof postData,
            {
                data:
                    | { success: true; email: string; error: undefined }
                    | { success: false; email: undefined; error?: string }
            }
        >(`/v1/web/microsoft/accounts/${sharePointAccount.uuid}/sharepoint/site`, postData)

        if (data.success) {
            await loadAccounts()
            closeSharePointModal()
            toast('success', t('web_me_options_sharepoint_saved'))
        } else {
            toast('error', t('web_me_options_linked_mailbox_error'))
            if (data.error) {
                toast('error', data.error, undefined, undefined, undefined, undefined, 5000)
            }
        }

        setSavingSharePoint(false)
    }

    async function removeSharePoint(accountId: string, siteId: string) {
        const remove = confirm(t('web_me_options_unlink_sharepoint_confirm'))
        if (remove) {
            const {
                data: { success, site },
            } = await del<{ data: { success: boolean; site: string } }>(
                `/v1/web/microsoft/accounts/${accountId}/sharepoint/sites/${siteId}`
            )

            if (success) {
                toast('success', t('web_me_options_sharepoint_unlinked', { site }))
            } else {
                toast('error', t('web_me_options_linked_mailbox_error'))
            }

            await loadAccounts()
        }
    }

    async function linkMailbox(account: Account) {
        const linkConfirm = confirm(
            t(account.mailboxLinked ? 'web_me_options_mailbox_confirm_unlink' : 'web_me_options_mailbox_confirm_link')
        )

        if (linkConfirm) {
            setLoading(true)

            const putData = {
                link: !account.mailboxLinked,
            }

            const { data } = await put<
                typeof putData,
                {
                    data:
                        | { success: true; linked: boolean; error: undefined }
                        | { success: false; linked: undefined; error?: string }
                }
            >(`/v1/web/oauth/accounts/${account.uuid}/link-mailbox`, putData)

            if (data.success) {
                await loadAccounts()
                closeSharePointModal()
                toast(
                    'success',
                    t(data.linked ? 'web_me_options_mailbox_linked' : 'web_me_options_mailbox_unlinked', {
                        account: account.email,
                    })
                )
            } else {
                toast('error', t('web_microsoft_sharepoint_error'))
                if (data.error) {
                    toast('error', data.error, undefined, undefined, undefined, undefined, 5000)
                }
            }

            setLoading(false)
        }
    }

    function oauthLogin(provider: Provider) {
        const state = createId()
        const expires = DateTime.local().plus({ minutes: 5 })

        setCookie(`${provider.reference}_oauth_state`, state, expires)
        setCookie(`${provider.reference}_oauth_redirect`, location.pathname, expires)

        const url = new URL(provider.loginUrl)
        url.searchParams.append('state', state)

        window.location.href = url.toString()
    }

    function openSharePointModal(account: Account) {
        setSharePointAccount(account)
        setSharePointSite(undefined)
        setSharePointLists([])
        showSharePointModal(true)
    }

    function closeSharePointModal() {
        showSharePointModal(false)
        setSharePointAccount(undefined)
        setSharePointSite(undefined)
        setSharePointLists([])
    }

    function onSelectedListChange(list: ListLight, selected: boolean) {
        setSharePointLists([...sharePointLists.filter(({ id }) => id !== list.id), { ...list, selected }])
    }

    function onSelectedSite(site: SiteLight) {
        setSharePointSite(site)

        if (site) {
            setSharePointLists(site.lists.map(list => ({ ...list, selected: false })))
        }
    }

    return (
        <>
            <h2 className="font-bold mb-2 text-lg">{t('web_me_options_linked_mailbox')}</h2>
            <div className="flex flex-col gap-2 mb-4">
                <p>{t('web_me_options_linked_mailbox_help1')}</p>
                <p>{t('web_me_options_linked_mailbox_help2')}</p>
                <p>{t('web_me_options_linked_mailbox_help3')}</p>
            </div>
            {loading ? (
                <SmallLoader />
            ) : (
                accounts.length > 0 && (
                    <div className="my-2 mb-4 flex flex-col gap-4">
                        <Modal
                            size="1/2"
                            isOpen={sharePointModal}
                            onCancel={() => closeSharePointModal()}
                            onRequestClose={savingSharePoint ? undefined : () => closeSharePointModal()}
                            onConfirm={async () => await saveSharePointSite()}
                            confirmDisabled={!sharePointSite || savingSharePoint}
                            cancelDisabled={savingSharePoint}
                            okLabel={t('web_me_options_sharepoint_confirm_list_selection')}
                            title={t('web_me_options_sharepoint_add')}
                            cancelLabel={t('web_cancel')}
                            bottomAlignment="center"
                        >
                            <SharePointAdd
                                isSaving={savingSharePoint}
                                account={sharePointAccount}
                                onSelectedListChange={onSelectedListChange}
                                onSelectedSite={onSelectedSite}
                                site={sharePointSite}
                            />
                        </Modal>
                        {accounts.map(account => (
                            <div key={account.uuid} className="flex flex-col gap-5 rounded shadow-md p-4">
                                <div className="flex flex-col md:flex-row gap-1 md:gap-8 md:items-center w-full">
                                    <span className="shrink-0 flex items-center gap-2">
                                        {account.provider.reference === 'google' && <Google className="h-7 w-7" />}
                                        {account.provider.reference === 'microsoft' && (
                                            <Microsoft className="h-7 w-7" />
                                        )}
                                        <span>{account.email}</span>
                                    </span>

                                    <button
                                        title={t('web_me_options_unlink_account')}
                                        type="button"
                                        className="flex"
                                        onClick={async () => await removeAccount(account.uuid)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} className="text-bittersweet" />
                                    </button>
                                </div>
                                {(!account.mailboxLinked || account.provider.reference === 'microsoft') && (
                                    <div className="flex gap-8">
                                        {!account.mailboxLinked && (
                                            <button
                                                title={t('web_me_options_mailbox_link')}
                                                type="button"
                                                className="flex items-center gap-2"
                                                onClick={async () => await linkMailbox(account)}
                                            >
                                                <FontAwesomeIcon icon={faPlus} />
                                                {t('web_me_options_mailbox_link')}
                                            </button>
                                        )}
                                        {account.provider.reference === 'microsoft' && (
                                            <>
                                                <button
                                                    title={t('web_me_options_sharepoint_add')}
                                                    type="button"
                                                    className="flex items-center gap-2"
                                                    onClick={() => openSharePointModal(account)}
                                                >
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    {t('web_me_options_sharepoint_add')}
                                                </button>
                                                <button
                                                    title={t('web_me_options_onedrive_add')}
                                                    type="button"
                                                    className="flex items-center gap-2"
                                                    onClick={() => alert(t('web_me_options_onedrive_add_coming_soon'))}
                                                >
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    {t('web_me_options_onedrive_add')}
                                                </button>
                                            </>
                                        )}
                                    </div>
                                )}
                                {account.mailboxLinked && (
                                    <div className="flex flex-col gap-2">
                                        <div className="flex items-center gap-4">
                                            <div className="w-8">
                                                {account.provider.reference === 'google' && (
                                                    <Gmail className="h-6 w-6" />
                                                )}
                                                {account.provider.reference === 'microsoft' && (
                                                    <Outlook className="h-8 w-8" />
                                                )}
                                            </div>
                                            <div className="flex flex-col w-1/2">
                                                <span>{t('web_me_options_linked_mailbox_collect_type_label')}</span>

                                                <Select
                                                    styles={selectStyles}
                                                    components={{ Input, Option: OptionWithTooltip }}
                                                    placeholder={t('web_placeholder_select')}
                                                    value={accountCollectTypes.find(
                                                        opt =>
                                                            opt.value ===
                                                            collectByAccount.find(c => c.accountId === account.uuid)
                                                                ?.collectType
                                                    )}
                                                    options={accountCollectTypes}
                                                    onChange={async opt =>
                                                        await accountCollectType(
                                                            account.uuid,
                                                            opt.value as MailCollectType
                                                        )
                                                    }
                                                />
                                            </div>

                                            <button
                                                title={t('web_me_options_unlink_mailbox')}
                                                type="button"
                                                className="flex"
                                                onClick={async () => await linkMailbox(account)}
                                            >
                                                <FontAwesomeIcon icon={faTrash} className="text-bittersweet" />
                                            </button>
                                        </div>

                                        {collectByAccount.find(c => c.accountId === account.uuid)?.collectType && (
                                            <div className="text-md text-pale-sky pl-12">
                                                {
                                                    accountCollectTypes.find(
                                                        ({ value }) =>
                                                            value ===
                                                            collectByAccount.find(c => c.accountId === account.uuid)
                                                                .collectType
                                                    ).help
                                                }
                                            </div>
                                        )}
                                    </div>
                                )}

                                {account.provider.reference === 'microsoft' && account.sharePointSites.length > 0 && (
                                    <div className="flex flex-col gap-2">
                                        <p className="font-bold flex gap-3 items-center">
                                            <SharePoint className="w-6 h-6" />
                                            {t('web_me_options_added_sharepoints')}
                                        </p>
                                        {account.sharePointSites.map(site => (
                                            <div key={site.id}>
                                                <div className="flex gap-4 items-center">
                                                    <span>
                                                        <span className="font-bold">{site.name}</span> (
                                                        <a href={site.url} target="_blank" rel="noreferrer">
                                                            {site.url}
                                                        </a>
                                                        )
                                                    </span>
                                                    <button
                                                        title={t('web_me_options_unlink_sharepoint')}
                                                        type="button"
                                                        className="flex"
                                                        onClick={async () =>
                                                            await removeSharePoint(account.uuid, site.uuid)
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} className="text-bittersweet" />
                                                    </button>
                                                </div>
                                                <div className="pl-4 mt-2">
                                                    <p className="font-semibold">
                                                        {t('web_microsoft_sharepoint_libraries')}
                                                    </p>
                                                    <div className="flex flex-col gap-2 pt-2">
                                                        {site.lists.map(list => (
                                                            <SharePointList
                                                                account={account}
                                                                site={site}
                                                                list={list}
                                                                key={list.id}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )
            )}
            <div className="flex gap-4">
                {providers.map(provider => (
                    <button
                        className="flex items-center gap-2 btn"
                        type="button"
                        key={provider.reference}
                        onClick={() => oauthLogin(provider)}
                    >
                        {provider.reference === 'google' && <Google className="h-4 w-4" />}
                        {provider.reference === 'microsoft' && <Microsoft className="h-6 w-6" />}
                        {t('web_me_options_link_account', { provider: provider.name })}
                    </button>
                ))}
            </div>
        </>
    )
}

export default OauthAccounts
